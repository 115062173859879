<template>
  <form @submit.prevent="createNewProfile" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <div class="addProfileContainer">
      <div
      class="cancel-button-container"

      >
      <div 
      @click="goBack"
      class="cancel-icon" > </div>
      </div>

      <div class="addProfile">
        <div>
          <p class="addProfileText">{{ $t("add profile") }}</p>
        </div>
        <div class="adminDetails">
          <!-- <img src="@/assets/icons/ProfileDp.png" class="adminImage" alt="" /> -->
          <!-- <p class="adminName">Admin</p>
        <p class="adminLable">Admin</p> -->
          <!-- <div class="adminprofileDp">
            <img v-if="!selectedFile" src="@/assets/icons/ProfileDp.png" class="adminImage" alt="" />
            <img v-if="selectedFile" :src="selectedFile" class="adminImage" alt="ProfileImage" />
            <div class="adminProfileOverlay"></div>
          </div>
          <div class="profileImageContainer">
            <input
              type="file"
              class="profileImageSelection"
              id="selectfile"
              title=""
              :value="selectedFile"
              accept="image/gif, image/jpeg, image/png"
              @change="inputFileChange($event)"
            />

            <img src="@/assets/icons/camera.svg" class="cameraImage" alt="" />
          </div>
        </div> -->
          <div class="profilePopupImage">
            <div class="profilePopupImageContainer">
              <img
                v-if="selectedImage"
                :src="selectedImage"
                class="subscriberImage"
                alt="profile-image"
              />
              <img v-else :src="defaultImage" class="subscriberImage" alt />
            </div>
            <!-- <div v-if="imageLoader" class="imageLoader">
              <Loading></Loading>
            </div> -->
            <div class="cameraImg" >
              <input
                type="file"
                id="selectfile"
                accept="image/gif, image/jpeg, image/png"
                @change="inputFileChange($event)"
              />
              <img
                src="@/assets/icons/camera.svg"
                :class="[localDisplayLang === 'ara' ? secPlace : ogPlace]"
                alt
              />
            </div>
          </div>
        </div>
      </div>
      <div class="profileDetails">
        <div class="form-group">
          <div
            class="error-container"
            v-if="formError && formError != 'Device Token Expired'"
          >
            <p class="error">{{ formError }}</p>
          </div>
        </div>

        <input
          type="text"
          v-model="profileName"
          class="profileName"
          :placeholder="`${namePlaceholder}`"
        />
        <!-- <input
          type="text"
          v-model="dateOfBirth"
          id="addProfileDatePicker"
          :placeholder="`${datePlaceholder}`"
          onfocus="(this.type='date')"
          class="profileDate"
          :max="getTodaysDate()"
        /> -->
        <input 
        type="text" 
        data-date="dd/mm/yyyy" 
        id="addProfileDatePicker" 
        @change= "getChangeFormat()" 
        onfocus="(this.type='date')"  data-date-format="DD/MM/YYYY" 
        class="profileDate" 
        :placeholder="`${datePlaceholder}`" v-model="dateOfBirth" 
        :max="getTodaysDate()"/>

      </div>


      <div v-if="showSetUpPin" class="setPin">
        <p class="setPinText">{{$t("set pin")}}</p>
        <label class="switch">
          <input v-model="isSetPin" type="checkbox" />
          <span class="slider round"></span>
        </label>
      </div>

      <div v-if="isSetPin" class="pinBlocks">
        <div class="otp_container_body">
          <div id="pininputs" class="pin-inputs" dir="ltr">
            <input
              v-for="(item, index) in numberOfOtpBlocks"
              :key="index"
              class="pin"
              type="password"
              v-model="userPin[item - 1]"
              @input="grabPin"
              maxlength="1"
            />
          </div>
        </div>
      </div>

      <div class="createButton">
        <Loading v-if="loading" />
        <button v-else type="submit" class="create">
          {{$t("create")}}
        </button>
      </div>
    </div>
  
  </form>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { mapActions } from "vuex";
import moment from 'moment';

export default {
  mixins: [Utility],
  components: {
    Loading: () => import("@/components/Templates/Loading.vue"),
  },
  data() {
    return {
      showSetUpPin: false,
      isSetPin: false,
      dateOfBirth: "",
      numberOfOtpBlocks: 4,
      userPin: [],
      profileName: null,
      formError: null,
      localDisplayLang: null,
      userProfilePin: "",
      selectedFile: null,
      secPlace: "secPlace",
      ogPlace: "ogPlace",
      subscriberImage: require("@/assets/icons/ProfileDp.png"),
      defaultImage: require("@/assets/icons/ProfileDp.png"),
      namePlaceholder:"",
      datePlaceholder:"",
      selectedImage: null,
      loading: false,
    };
  },

  watch: {
    isSetPin(val) {
      console.log("THE SET PIN -> ", val);
      if (val) {
        this.setUpPinBlocks();
      }
    },

    dateOfBirth(val) {
      const age = this.validateAgeForProfile(val);
      console.log("the AGE --> ", age);
      if (val && age > 13 && age < 120) {
        this.showSetUpPin = true;
        this.isSetPin = true;
      } else {
        this.showSetUpPin = false;
        this.isSetPin = false;
      }
    },

    formError() {
      setTimeout(() => {
        this.formError = null;
      }, 5000);
    },
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
  },

  mounted() {
    this.namePlaceholder = this.$t("name");
    this.datePlaceholder = this.$t("date of birth");
  },

  methods: {
    ...mapActions(["createProfile", "fileUpload"]),
  getChangeFormat () {
     document.getElementById('addProfileDatePicker').setAttribute(
        "data-date",
        moment(this.dateOfBirth, "YYYY-MM-DD")
        .format('DD/MM/YYYY')
    )

  },
    goBack(){
      let currentLanguage = this.getCurrentLanguageUrlBase();
      this.$router.push({ name: "Home", params: { lang: currentLanguage } });
    },

    inputFileChange(event) {
      let uploadedFile = event.target.files[0];

      if (!uploadedFile) return;

      let imageSize = Math.round(uploadedFile.size / 1000);

      if (imageSize < 10 || imageSize > 1000) {
        this.formError = this.$t("Image size should be betwwn 10 kb to 1 MB");
        return;
      }

      this.selectedFile = uploadedFile;

      console.log("THE UPLOADED FILE _ IMAGE -> ", this.selectedFile);

      this.selectedImage = URL.createObjectURL(this.selectedFile);

      this.uploadFile(uploadedFile);
    },

    uploadFile(uploadedFile) {
      let fileObj = {
        filename: uploadedFile,
      };

      this.fileUpload(fileObj)
        .then((response) => {
          if (response.data.reason) {
            this.formError = response.data.reason;
          } else {
            this.uploadedImageFile = response.data.success;
            console.log("File Uploaded successfully --- > ", response)
          }
        })
        .catch((error) => console.log(error));
    },

    getTodaysDate() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      today = yyyy + "-" + mm + "-" + dd;
      return today;
      // document.getElementById("addProfileDatePicker").setAttribute("max", today);
    },

    grabPin() {
      if (this.userPin.length > this.numberOfOtpBlocks - 1) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += element;
        });
        this.userProfilePin = pin;
      }
    },

    setUpPinBlocks() {
      setTimeout(() => {
        this.pinFunctionality(document.getElementById("pininputs"));
      }, 500);
    },

    createNewProfile() {
      if(this.loading){
        return;
      }
      if (!this.dateOfBirth) {
        this.formError = this.$t("Date of birth is Required");
        return;
      }

      if(this.isSetPin && this.userPin.length < this.numberOfOtpBlocks) {
        this.formError = this.$t("The profile pin is mandatory");
        return
      }

      if (this.validateName(this.profileName) && this.dateOfBirth) {
        const kidsMode = this.validateAgeForProfile(this.dateOfBirth) <= 13 ? "YES" : "NO";
        const payload = {
        profilename: this.profileName,
        [kidsMode === 'YES' ? 'kidsmode' : 'kidsMode']: kidsMode,
        dob: this.dateOfBirth,
        displaylanguage: this.localDisplayLang
         };


        if (this.uploadedImageFile) {
          payload.picture = this.uploadedImageFile;
        }

        if (this.userPin && this.userPin.length > 0) {
          payload.profilepin = this.userProfilePin;
        }
        this.loading = true;
        this.createProfile(payload)
          .then((response) => {
            this.loading = false;
            if (!response.reason) {
              let currentLanguage = this.getCurrentLanguageUrlBase();

              this.$router.push({ name: "switchProfile", params: { lang: currentLanguage } });
              // this.$router.push("/switchProfile");
            } else {
              this.formError = response.reason;
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log("Profile creation error -> ", err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";


#addProfileDatePicker {
    position: relative;
    position: relative;
    width: 14rem;
    height: 2.5rem;
    color: white;
    margin: 0.4rem 0;
    padding: 0.7rem;
    color: white;
}

#addProfileDatePicker:before {
    position: absolute;
    content: attr(data-date);
    display: inline-block;
}

 #addProfileDatePicker::-webkit-datetime-edit, #addProfileDatePicker::-webkit-inner-spin-button, #addProfileDatePicker::-webkit-clear-button {
    display: none;
}

 #addProfileDatePicker::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 2px;
    top: 4px;
    height: 13rem;
    width: 11%;
    color: black;
    opacity: 1;
}


input[type=text]:focus {
  border: 1px solid red !important;
}
.addProfileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 250px);
  .addProfile {
    .addProfileText {
      margin: 2rem 0;
      letter-spacing: 0px;
      color: #ffffff;
      text-align: center;
      opacity: 1;
      font-size: 1.2rem;
      font-weight: 600;
      font-family: $font-regular;
    }
    .adminDetails {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100px;
        width: 100px;
        border-radius: 5em;

      }

      .profilePopupImage {
        // height: 100px;
        // width: 100px;
        position: relative;
        
        .profilePopupImageContainer {
          height: 100%;
          .subscriberimage {
            // width: 100%;
            // height: 100%;
            width: inherit;
            height: inherit;
            border-radius: 50%;
          }
        }
        .imageLoader {
          position: absolute;
          
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .cameraImg {
          position: absolute;
          top: 0%;
          width: 100%;
          height: 100%;
          
          border-radius: 52%;
          background: rgba(0, 0, 0, 0.26);
          opacity: 0;
          cursor: pointer;
          z-index: 100;
          &:hover {
            opacity: 1;
            cursor: pointer;
          }
          .ogPlace {
            position: relative;
            width: 20px;
            top: -100%;
            left: 40%;
            z-index: -1;
          }
          .secPlace {
            position: relative;
            width: 20px;
            top: -101%;
            left: -40%;
            z-index: -1;
          }

          input[type="file"] {
            padding: 2.5em;
            opacity: 0;
            z-index: 100;
            border-radius: 5em;
            width: 100%;
            height: 100%;
            &:focus {
              background-color: none;
            }
          }
        }
      }
    }
    .adminName {
      margin-top: 1rem;
      opacity: 1;
      font-size: 1.2rem;
      font-weight: 600;
      font-family: $font-regular;
      color: $clr-dark-gd10;
    }
    .adminLable {
      margin-top: 1rem;
      opacity: 1;
      font-size: 0.9rem;
      font-weight: 600;
      font-family: $font-regular;
      background-color: #fcc10f;
      padding: 2px 2px 2px 2px;
      text-align: center;
      color: black;
      border-radius: 4px;
    }
  }

  // .addProfile{
  //   .adminDetails{

  //   }
  // }
  .profileDetails {
    margin: 2rem 0 0 0 ;
    display: flex;
    flex-direction: column;
    .profileName {
      width: 14rem;
      // height: 2rem;
    }
    .profileDate {
      // height: 2rem;

      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
  }

  .setPin {
    margin-top: 0.5rem;
    width: 14rem;

    display: flex;
    justify-content: space-between;
    .setPinText {
      color: $clr-light-gd4;
      font-weight: 600;
      font-family: $font-regular;
      font-size: 0.9rem;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 16px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $clr-light-gd4;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 4px;
      top: 2px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #ec1c65;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px black;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
  .createButton {
    .create {
      cursor: pointer;
      width: 14rem;
      height: 40px;
      margin-top: 1rem;
      color: #ffffff;
      background-color: #E81C25;
      font-weight: 600;
      font-family: $font-regular;
      font-size: 0.9rem;
      border: none;
      border-radius: 4px;
    }
  } 
  .create:hover {
     background-color: #ffffff;
     color: #E81C25;
     cursor: pointer
}
.pin-inputs {
    width: 15rem;
    display: flex;
    justify-content: space-between;
    .pin {
      margin: 1rem 5px;
      text-align: center;
      padding: 0.5rem 0.2rem;
    }
  }
}

.profile-popup {
  padding: 3.4rem;
  &-title {
    color: $clr-light-gd4;
    font-size: 1.4rem;
    color: #c1c1c1;
    font-family: $font-regular;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 1.2rem;
  }
  &-image {
    position: relative;
    margin-bottom: 1.2rem;
    width: 100px;
    height: 100px;
    &-container {
      height: 100%;
      .subscriberimage {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .image-loader {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .camera-img {
      position: absolute;
      top: 0%;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.26);
      opacity: 0;
      cursor: pointer;
      z-index: 100;
      &:hover {
        opacity: 1;
      }
      // img {
      //   position: relative;
      //   width: 20px;
      //   top: -50%;
      //   left: 39%;
      //   z-index: -1;
      // }
      .og-place {
        position: relative;
        width: 20px;
        top: -50%;
        left: 39%;
        z-index: -1;
      }
      .sec-place {
        position: relative;
        width: 20px;
        top: -50%;
        left: -40%;
        z-index: -1;
      }

      input[type="file"] {
        padding: 2.5em;
        opacity: 0;
        z-index: 100;
        border-radius: 5em;
        width: 100%;
        &:focus {
          background-color: none;
        }
      }
    }
  }
}

.cancel-button-container{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-right: 40px;
  margin-top: 20px;
}

.cancel-icon{
  color: #4F4F50;
  font-size: 30px;
  cursor:pointer;
}

.cancel-icon:hover{
  color:  $primary-btn-color;
  font-size: 30px;
}

.cancel-icon:after{
  display: inline-block;
  content: "\00d7"; 
}



</style>
